import * as React from "react"
import Layout from "../components/layout"

const HirekPage = () => {

  return <Layout>
    Hírek
  </Layout>
}

export default HirekPage
